export const PROCESSING_SCREEN_DATA = {
  header: 'Processing',
  headerIcon: '753b9194-8240-40e3-acf9-1322b1ae5d3d',
  sections: [
    {
      name: 'URL API',
      description:
        'The simplest way to process images on the fly. Each of your images has a unique URL that you can append transformations to, chained with /-/ dividers. They’re intuitively named, easy to learn, and they happen instantly on our CDN when image versions are requested for delivery.',
      image: 'f429b28c-5293-4da9-b777-08c52338bbd8',
      buttons: [
        {
          name: 'Product page',
          url: '/cdn/image-processing/',
        },
        {
          name: 'Documentation',
          url: '/docs/transformations/image/',
        },
        {
          name: 'List of all operations',
          url: '/docs/transformations/image/#image-transformations-list',
        },
      ],
    },
    {
      name: 'Smart image compression',
      description:
        'Reduce the size of your images by up to 70% with the fastest compression algorithm on the market. It compresses images to just before human-visible artifacts can appear, so there’s no loss of quality. All automagically, of course.',
      image: '',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/transformations/image/compression/#smart-compression',
        },
      ],
    },
    {
      name: 'Automated AVIF and WebP',
      description:
        'Optimize images with the next gen formats. AVIF beats JPEG with around 30% less file size with similar visual quality and WebP is a great modern all-rounder with transparency support and both lossy and lossless compression methods. Our CDN chooses the output format automatically based on the user’s browser support and the image properties.',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/transformations/image/compression/#operation-format',
        },
      ],
    },
    {
      name: 'Auto image format',
      description:
        'Give modern browsers the modern image formats they need: smaller and snappier to load. Convert images on the fly into WebP, AVIF, PNG, or JPEG, or ask Uploadcare to select the best format automatically based on each user’s browser support.',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/transformations/image/compression/#operation-format',
        },
      ],
    },
    {
      name: 'Resize & crop',
      description:
        'Request the correctly sized images on the fly. These two related geometric operations happen on CDN via URL API, which means your original image is preserved. Their order matters: if you resize an image first, crop will be applied to the new size.',
      image: '',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/transformations/image/resize-crop/#size',
        },
      ],
    },
    {
      name: 'Content-aware smart cropping',
      description:
        'S​ay goodbye to manual media editing. Content-aware smart cropping finds the main subject of an image and automatically trims the background areas around it to fit the scale and dimensions you need. It recognizes faces, objects, and high-contrast areas, with many options to fine-tune.',
      image: 'cd28d25a-d0b4-44b8-9e31-8e3bfe921e92',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/delivery/adaptive-delivery/#operation-smart-crop',
        },
      ],
    },
    {
      name: 'Smart resize',
      description:
        'Content-aware resize helps retaining original proportions of faces and other visually sensible objects while resizing the background using intelligent algorithms.',
      image: '',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/transformations/image/resize-crop/#operation-smart-resize',
        },
      ],
    },
    {
      name: 'Zoom objects',
      description:
        'Scale objects similarly across multiple images. Zoom in 1-100% to objects in your images, detected automatically by our AI. Original image resolution is preserved.',
      image: '',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/transformations/image/resize-crop/#operation-zoom-objects',
        },
      ],
    },

    {
      name: 'Background removal',
      description:
        'Remove backgrounds with chilling accuracy with remove.bg: the industry standard in background removal AI. Access the add-on right inside the Uploadcare platform: no friction, no backgrounds, no worries!',
      image: 'e4a42477-9739-48d9-b9f6-36938042f69c',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/remove-bg/',
        },
        {
          name: 'API reference',
          url: '/api-refs/rest-api/v0.7.0/#operation/removeBgExecute',
        },
      ],
    },
    {
      name: 'Auto-enhance',
      description:
        'A quick way to make photos look better: automatically apply levels, contrast, and saturation sharpening.',
      image: '',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/transformations/image/colors/#operation-enhance',
        },
      ],
    },
    {
      name: 'Color adjustments',
      description:
        'Adjust basic image properties (brightness, contrast, gamma, saturation etc); deliver grayscale and inverted colors.',
      image: '',
      buttons: [
        {
          name: 'Basic color adjustments',
          url: '/docs/transformations/image/colors/',
        },
      ],
    },
    {
      name: 'Color profile management',
      description:
        'Accurately convert CMYK to RGB using ICC profile data embedded in images. Include or strip the profile data after conversion.',
      image: '',
      buttons: [
        {
          name: 'Color profile management',
          url: '/docs/transformations/image/colors/#image-color-profile-management',
        },
      ],
    },
    {
      name: 'Photo filters',
      description:
        'Add a distinct look and feel to photos across your website with our library of photo filters.',
      image: '852e17ab-cbe8-44af-956a-0a01a656b51c',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/transformations/image/photo-filters/',
        },
      ],
    },
    {
      name: 'Blur & sharpen',
      description:
        'Apply Gaussian Blur or sharpen effects with handy URL operations. Need to blur faces? Simply add the faces option and let our AI do the rest!',
      image: '',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/transformations/image/blur-sharpen/',
        },
      ],
    },
    {
      name: 'Overlays',
      description:
        'Layer images one over another and use intuitive URL operations to set transparency and mutual positioning. Especially useful for watermarking.',
      image: '',
      buttons: [
        {
          name: 'Image overlays',
          url: '/docs/transformations/image/overlay/',
        },
        {
          name: 'Text overlays',
          url: '/docs/transformations/image/overlay/',
        },
        {
          name: 'Watermarks guide',
          url: '/docs/guides/watermarks/',
        },
      ],
    },
    {
      name: 'Rotate & flip',
      description:
        'Rotate, flip, and mirror. Or set up an EXIF-based automatic rotation. It’s all in there 🔁',
      image: '',
      buttons: [
        {
          name: 'Auto & manual rotatation',
          url: '/docs/transformations/image/rotate-flip/#operation-autorotate',
        },
        {
          name: 'Flip & mirror',
          url: '/docs/transformations/image/rotate-flip/#operation-flip',
        },
      ],
    },
    {
      name: 'Face detection',
      description:
        'This specialized algorithm returns accurate coordinates of faces in photos, making it easy to automatically crop, blur out, or tag images of people. Use face detection to automatically generate perfectly centered and standardized profile pictures at scale.',
      image: '',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/intelligence/face-detection/',
        },
      ],
    },
    {
      name: 'Color recognition',
      description:
        'Get the prevailing colors in an image. Specify the number of colors you want and recieve a JSON with RGB values. Use it to, for example, build color-based search filters.',
      image: 'a3bad6cb-ea59-466b-85d5-139229a60ee1',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/intelligence/color-recognition/',
        },
      ],
    },
    {
      name: 'Video processing',
      description:
        'Encode video files from all popular formats to MP4, WEBM, OGG, and even GIFs (it’s pronounced  gif 🤓). Adjust quality, dimensions, cut out sections, generate thumbnails and more. While image processing happens instantly via URL API, video processing requires you to start a job via REST API.',
      image: 'ec2b3fdb-e16f-4f8c-937e-b3ac5ca2c1a4',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/transformations/video-encoding/#video-processing',
        },
        {
          name: 'API reference',
          url: '/api-refs/rest-api/v0.7.0/#operation/videoConvert',
        },
      ],
    },
    {
      name: 'Document conversion',
      description:
        'Rule the office by ruling the entire office suite, with reliable conversion between popular Microsoft, Apple, OpenDocument, text, data and even Adobe file formats. Most docs can be converted to images, too.',
      image: '',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/transformations/document-conversion/#document-conversion',
        },
        {
          name: 'API reference',
          url: '/api-refs/rest-api/v0.7.0/#operation/documentConvert',
        },
      ],
    },
    {
      name: 'GIF to video',
      description:
        'Animated GIFs are known not only for memes but also for huge file sizes and a battery drain while playing. Convert them to modern and efficient MP4 and WebM on the fly.',
      image: '',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/transformations/gif-to-video/',
        },
      ],
    },
  ],
};

import React, { useEffect, useState } from 'react';

import { Link } from '~/src/components/Link';
import { ButtonBody, ButtonIcon } from './styles';

export const ToStartButton = ({ firstScreenRef = {} }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const firstScreenElement = firstScreenRef.current;

    if (!firstScreenElement) return;

    const handleIntersect = (entries) => {
      for (const entry of entries) {
        const { intersectionRatio } = entry;
        setIsVisible(intersectionRatio < 0.5);
      }
    };

    const observer = new IntersectionObserver(handleIntersect, {
      root: null,
      rootMargin: '0px',
      threshold: [0.5],
    });

    observer.observe(firstScreenElement);

    return () => {
      observer.unobserve(firstScreenElement);
    };
  }, [firstScreenRef]);

  return (
    <ButtonBody as={Link} to="#top" $isVisible={isVisible}>
      <ButtonIcon uuid="94d81979-8b36-4fb8-ba38-fc36424df231" />
    </ButtonBody>
  );
};

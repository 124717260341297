export const DELIVERY_SCREEN_DATA = {
  header: 'Delivery',
  headerIcon: '9d2e0d5d-93f1-4721-90cc-01dffe48a5a9',
  sections: [
    {
      name: 'Smart CDN',
      description:
        'Every uploaded file is immediately available on the Uploadcare Smart CDN: a multi-vendor global network with a proprietary layer that delivers maximum speed and on-the-fly transformations via URL API.',
      image: '',
      buttons: [
        {
          name: 'Product page',
          url: '/cdn/',
        },
        {
          name: 'Docs',
          url: '/docs/delivery/cdn/#content-delivery-network',
        },
      ],
    },
    {
      name: 'On the fly image optimization',
      description:
        'The most efficient way to process images. Chain operations with URL API, simplify batch image processing, and let our Image CDN create lighter, optimized images automatically.',
      image: '',
      buttons: [
        {
          name: 'Product page',
          url: '/cdn/image-cdn/',
        },
        {
          name: 'Docs',
          url: '/docs/transformations/image/',
        },
      ],
    },
    {
      name: 'Proxy',
      description:
        'Deliver remote media files. Use Uploadcare CDN with your existing assets — just add a prefix to the URLs and your files will be distributed from our CDN. On-the-fly image processing works with Proxy too.',
      image: '57f36a21-bbad-4965-99f8-dc7f32f5ce55',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/delivery/proxy/',
        },
      ],
    },
    {
      name: 'Auto responsive images',
      description:
        'Deliver always perfect image formats for any page layout and each device. Our CDN generates image versions on the spot based on the layout and client’s device properties. We’ve built lr-img: universal web component that do it all for you: works with <img>, srcset, <picture> or css backgrounds.',
      image: 'e873c690-50da-4a69-a07d-87152b056272',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/adaptive-image/',
        },
      ],
    },
    {
      name: 'Multiple CDN vendors',
      description:
        'Why rely on only one CDN provider? We deliver across multiple CDNs, with routing optimization to maximize performance. And if you depend on a specific infrastructure, you can bring your own CDN.',
      image: '',
      buttons: [
        {
          name: 'CDN product page',
          url: '/cdn/',
        },
      ],
    },
    {
      name: 'Search engine indexing',
      description:
        'Prevent search engines from analyzing and indexing content in your projects. Uploadcare adds response headers to restrict indexing of any files in your project, making sure your files don’t turn up in search results or impact your overall SEO.',
      image: '',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/delivery/cdn/#search-engine-indexing',
        },
      ],
    },
    {
      name: 'Signed URLs',
      description:
        'Grant access to specific files for specific users. Signed URLs contain authentication tokens that give access only ones with the link.',
      image: '',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/security/secure-delivery/',
        },
      ],
    },
    {
      name: 'CDN CNAME',
      description:
        'Use your own domain for CDN links to your files stored on Uploadcare. Replace the default ucarecdn.com domain with e.g. cdn.mycompany.com for your URLs.',
      image: '',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/start/settings/#custom-cdn-cname',
        },
      ],
    },
  ],
};

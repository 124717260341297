import React from 'react';

import { ButtonTypeEnum, ButtonSizeEnum } from '@uc-common/button';
import { Container as Wrapper } from '@uc-common/container';

import { toKebabCase } from '~/src/utils/toKebabCase';
import { FeaturesCategoryContainer } from './styles/FeaturesCategory.styles';
import {
  FeaturesButton,
  FeaturesSectionHeading,
  FeaturesSubtitle,
  ScreenHeaderContainer,
  ScreenHeaderIcon,
  ScreenInfoSection,
  ScreenDescriptionWrapper,
  ScreenInfoDescription,
  ScreenInfoButtonsContainer,
  ScreenButtonLink,
  ScreenInfoImage,
  ScreenInfoImageContainer,
} from '../styles/styled-components';

export const FeaturesCategory = ({ header, headerIcon, sections = [] }) => (
  <FeaturesCategoryContainer data-navbar-theme="dark">
    <Wrapper>
      <ScreenHeaderContainer id={toKebabCase(header)}>
        <ScreenHeaderIcon uuid={headerIcon} />
        <FeaturesSectionHeading>{header}</FeaturesSectionHeading>
      </ScreenHeaderContainer>

      {sections.map((section) => (
        <ScreenInfoSection key={section.name} id={toKebabCase(header + section.name)}>
          <FeaturesSubtitle>{section.name}</FeaturesSubtitle>
          <ScreenDescriptionWrapper>
            <ScreenInfoDescription>{section.description}</ScreenInfoDescription>
            <ScreenInfoButtonsContainer>
              {section.buttons.map(({ name, url }) => (
                <FeaturesButton
                  forwardedAs={ScreenButtonLink}
                  key={name}
                  to={url}
                  type={ButtonTypeEnum.TRANSPARENT}
                  size={ButtonSizeEnum.EXTRA_SMALL}
                  ghost
                >
                  {name}
                </FeaturesButton>
              ))}
            </ScreenInfoButtonsContainer>
          </ScreenDescriptionWrapper>
          <ScreenInfoImageContainer>
            {section.image && <ScreenInfoImage key={section.image} uuid={section.image} />}
          </ScreenInfoImageContainer>
        </ScreenInfoSection>
      ))}
    </Wrapper>
  </FeaturesCategoryContainer>
);

import styled, { css } from 'styled-components';
import Image from '@uc-common/image';

export const ButtonBody = styled.button(
  ({ theme: { tablet }, $isVisible = false }) => css`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: fixed;
    left: 15px;
    bottom: 15px;
    background-color: #14171e;
    color: #8fa3ae;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;

    ${$isVisible &&
    css`
      pointer-events: auto;
      opacity: 1;
    `}

    &:hover {
      background-color: #0f1113;
      color: #ffffff;
    }

    &:active {
      background-color: #242832;
      color: #333845;
    }

    ${tablet()} {
      left: 30px;
      bottom: 30px;
    }
  `
);

export const ButtonIcon = styled(Image)`
  margin: auto;
`;

export const MANAGEMENT_SCREEN_DATA = {
  header: 'Management',
  headerIcon: '69885ae5-b5a6-49d4-b0f3-bde9db54f6b8',
  sections: [
    {
      name: 'REST API',
      description:
        'Powerful API to handle all things files. Use it to access and manipulate your files, fire up platform add-ons, and access low-level Uploadcare features. Storage, webhooks, custom metadata, object recognition, background removal, video and docs conversion — it’s all here.',
      image: '670a0d58-39fe-41e0-951b-6c09a10648ea',
      buttons: [
        {
          name: 'REST API reference',
          url: '/api-refs/rest-api/',
        },
        {
          name: 'APIs overview',
          url: '/api/',
        },
      ],
    },
    {
      name: 'Content info',
      description:
        'Fetch detailed insights on your uploaded files, individually or in bulk. Review MIME types, size, resolution, format and metadata.',
      image: '',
      buttons: [
        {
          name: 'API reference',
          url: '/api-refs/rest-api/v0.7.0/#operation/fileInfo',
        },
      ],
    },
    {
      name: 'Custom metadata',
      description:
        'Let Uploadcare be your complete file backend: add custom metadata to your files and always know what’s what. Write order numbers, item IDs, author names – you name it!',
      image: '473721f8-321a-4930-8c16-99e612a2c0ef',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/file-metadata/',
        },
        {
          name: 'API reference',
          url: '/api-refs/rest-api/v0.7.0/#tag/File-metadata',
        },
      ],
    },
    {
      name: 'AWS Rekognition',
      description:
        'Get to know your media with machine learning. Tag people, objects and other content classes in your images, with accurate confidence levels. This feature uses AWS Rekognition DetectLabels operation.',
      image: '2b505be3-0b55-4027-9215-60be1b5bc206',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/intelligence/object-recognition/',
        },
        {
          name: 'API reference',
          url: '/api-refs/rest-api/v0.7.0/#operation/awsRekognitionExecute',
        },
      ],
    },
    {
      name: 'Webhooks',
      description:
        'Subscribe to file events and trigger corresponding actions within your app. For example, get a notification when a file is uploaded or if the uploaded file is infected.',
      image: '0063b5c2-0d9b-404d-85c3-d286866d5b1c',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/start/settings/#project-settings-advanced-webhooks',
        },
        {
          name: 'Secure webhooks',
          url: '/docs/webhooks/#signed-webhooks',
        },
      ],
    },
    {
      name: 'Multiuser projects',
      description:
        'Get your team on board. Add multiple collaborators to your projects, with access to project files and settings.',
      image: '50f18aab-bd97-4d08-909b-50faa928219b',
      buttons: [
        {
          name: 'Team settings',
          url: '/docs/start/settings/#project-teams',
        },
      ],
    },
    {
      name: 'Usage analytics',
      description:
        'Gaze upon hi-res usage data — only a dashboard click away. Track your uploads, CDN traffic, locations... and bandwidth we’ve saved you ✨ Learn where your users are coming and uploading from. Understand your file types, sizes, and processing stats, and see how you’re using APIs, webhooks, libraries and more.',
      image: 'f4dc9ebc-ed6d-4b4d-83d1-863bf1e4bb7f',
      buttons: [],
    },
    {
      name: 'Access logs',
      description:
        'Review all API requests (POST, GET, PUT, DELETE), errors, and infected files in your Dashboard.',
      image: '4ba923e3-d68b-4170-b296-af43837e7520',
      buttons: [],
    },
    {
      name: 'Custom storage',
      description:
        'Automatically copy files to a custom Amazon S3 bucket instead. One bucket connects to one or more Uploadcare projects. Great for building deep file management customization and complying with company storage policy.',
      image: '',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/s3-integration/',
        },
      ],
    },
    {
      name: 'Backup storage',
      description:
        'Protect your stored files with backups to a custom S3 bucket. Connect the bucket once, and the system will copy all incoming files as they come.',
      image: '',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/s3-integration/#backup-storage',
        },
      ],
    },
    {
      name: 'Migration',
      description:
        'Switching to Uploadcare from another file management provider? We’re humbled — and happy to help! Use our free Migro Tool to make the process a breeze of automation.',
      image: '',
      buttons: [
        {
          name: 'Migration guide',
          url: '/docs/guides/migro/',
        },
        {
          name: 'Example: migration from Filestack',
          url: '/docs/guides/from-filestack/',
        },
      ],
    },
  ],
};

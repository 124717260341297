import styled, { css } from 'styled-components';

export const FeaturesCategoryContainer = styled.section(
  ({ theme: { laptop } }) => css`
    padding: 0 0 100px;
    background-color: #000000;

    ${laptop()} {
      padding: 0 0 130px;
    }
  `
);

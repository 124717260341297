export const UPLOADING_SCREEN_DATA = {
  header: 'Uploading',
  headerIcon: 'a79dd2c2-53e6-45b1-a6d2-7368e370a62d',
  sections: [
    {
      name: 'File Uploader',
      description:
        'Flagship uploading GUI designed for to be user friendly. It’s a responsive HTML5 widget that fits your stack and branding. It’s easy to integrate. You can customize basic design via dashboard, or go deep with CSS & JavaScript.',
      image: '758454bf-4f25-4b48-8eb1-100474aea7b6',
      buttons: [
        {
          name: 'Product page',
          url: '/products/file-uploader/',
        },
        {
          name: 'Docs',
          url: '/docs/uploads/file-uploader/',
        },
        {
          name: 'Customization options',
          url: '/docs/uploads/file-uploader-options/',
        },
      ],
    },
    {
      name: 'File uploading',
      description:
        'It’s literally in our name: Uploadcare ✨ Accept and store files from your users with one safe, robust and compliant solution. Use our ready-made uploader interface and infrastructure, or integrate the parts you need with flexible APIs.',
      image: '',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/uploads/',
        },
      ],
    },
    {
      name: 'Upload API',
      description:
        'This API handles all things uploads: direct, multipart and URL uploading, adding custom metadata, getting malware scanner reports. It powers our File Uploader, but you can also access it directly to receive files via your own UI.',
      image: '7b1d82a7-a15c-43dd-9394-1ae1c9cddbff',
      buttons: [
        {
          name: 'API reference',
          url: '/api-refs/upload-api/',
        },
        {
          name: 'APIs overview',
          url: '/api/',
        },
      ],
    },
    {
      name: 'Uploading API clients',
      description:
        'Integrate faster with our official API clients: JavaScript, PHP, Python, Ruby, Ruby on Rails, Java, Golang, Rust, Swift (iOS/macOS), Kotlin (Android).',
      image: '',
      buttons: [
        {
          name: 'API clients list',
          url: '/docs/integrations/#clients',
        },
      ],
    },
    {
      name: 'File Uploader integrations',
      description:
        'Add uploading superpowers to your app with wrappers and libraries for your stack: React File Uploader, Angular File Uploader, iOS File Uploader, Android File Uploader.',
      image: '7bde2652-0777-4347-a1b9-a5afee33c921',
      buttons: [
        {
          name: 'Integrations',
          url: '/docs/integrations/#wrappers',
        },
      ],
    },
    {
      name: 'Upload sources',
      description:
        'Allow file uploads from 14 sources. We support local storage, device cameras, any URLs, and most cloud services and social networks including Instagram, Facebook, Dropbox and Google Drive.',
      image: '36388d0a-e518-4ef0-a4f3-32141223a617',
      buttons: [
        {
          name: 'List of sources',
          url: '/docs/uploads/file-uploader/#upload-source-list',
        },
      ],
    },
    {
      name: 'Image Editor',
      description:
        'Help your users upload better images. The File Uploader comes with a GUI-based image editor, featuring all primary photo editing tools: cropping, rotating, color tweaking and photo filtering. All in a sleek, intuitive design.',
      image: '499a439e-aaea-4cb2-ad2f-b2a09f2b6cde',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/uploads/image-editor/',
        },
      ],
    },
    {
      name: 'Accelerated uploads',
      description:
        'Plug into a global infrastructure engineered specifically for uploads. Files travel to our servers via a network of accelerated nodes — like CDN in reverse, the system picks the nodes closest to your user to maximize speed and reliability at any scale.',
      image: '',
      buttons: [],
    },
    {
      name: 'Large files uploading',
      description:
        'Large files are split into chunks and uploaded in concurrent streams to maximize upload speed. You can set the multipart threshold anywhere from 10 MB (default) to 100 MB. We support files up to 5TB in size.',
      image: '9c99d70d-5cb2-42ad-9454-6e8dcbe92baf',
      buttons: [
        {
          name: 'Multipart uploads',
          url: '/docs/uploads/file-uploader-options/#option-multipart-min-size',
        },
      ],
    },
    {
      name: 'Storage',
      description:
        'Secure, compliant and durable storage for your files. Only originally uploaded files take up storage space: derivatives made with URL API are cached directly on our CDN, so you get unlimited file versions on the house 🎉',
      image: '',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/uploads/storage/',
        },
      ],
    },
    {
      name: 'MIME-type filtering',
      description:
        'Set up server-side validation: restrict the types of files that can be uploaded to your project.',
      image: '',
      buttons: [
        {
          name: 'Backend validation',
          url: '/docs/moderation/#file-types',
        },
      ],
    },
    {
      name: 'File size validation',
      description: 'Set the maximum file size for uploaded files on the server side, up to 5TB.',
      image: '',
      buttons: [
        {
          name: 'Backend validation',
          url: '/docs/moderation/#maximum-file-size',
        },
      ],
    },
    {
      name: 'Uploading CNAME',
      description:
        'Use your own domain name for the Upload API and source endpoints, e.g. upload.mydomain.com instead of upload.uploadcare.com. You can use the same domain across several projects.',
      image: '',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/start/settings/#project-settings-custom-uploading-cname',
        },
      ],
    },
    {
      name: 'Custom OAuth apps',
      description:
        'Directly connect your app to external upload sources via oAuth 2. Users will now see your app name, icon, and legal documents when authenticating with Facebook, Google Drive, Dropbox etc. to upload files.',
      image: '',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/start/settings/#project-settings-advanced-oauth',
        },
        {
          name: 'Examples',
          url: '/docs/guides/oauth/',
        },
      ],
    },
    {
      name: 'Signed uploads',
      description:
        'Control who can upload files to your account by authenticating upload requests. You generate a token on your backend (HMAC/SHA256 signature) that your app will pass to File Uploader or API to allow uploads.',
      image: '',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/security/secure-uploads/',
        },
      ],
    },
    {
      name: 'Malware protection',
      description:
        'Your uploads are continually scanned for infected or malicious files using ClamAV, a high-performance antivirus engine.',
      image: '9dd05849-7317-4ea0-b09f-382d29b793e5',
      buttons: [
        {
          name: 'Docs',
          url: '/docs/security/malware-protection/',
        },
        {
          name: 'API reference',
          url: '/api-refs/rest-api/v0.7.0/#operation/ucClamavVirusScanExecute',
        },
      ],
    },
  ],
};

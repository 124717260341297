import React from 'react';
import { Container as Wrapper } from '@uc-common/container';

import { toKebabCase } from '~/src/utils/toKebabCase';
import { FeaturesBigHeading } from '../styles/styled-components';

import {
  FeaturesScreenContainer,
  FeaturesScreenCardsWrapper,
  FeaturesScreenDescription,
  FeaturesScreenCard,
  CardList,
  CardListItem,
  CardIcon,
  CardHeading,
} from './styles/FeaturesScreen.styles';

import { MANAGEMENT_SCREEN_DATA } from '../ManagementScreen/data';
import { DELIVERY_SCREEN_DATA } from '../DeliveryScreen/data';
import { PROCESSING_SCREEN_DATA } from '../ProcessingScreen/data';
import { UPLOADING_SCREEN_DATA } from '../UploadingScreen/data';

const FEATURES_CARD_DATA = [
  UPLOADING_SCREEN_DATA,
  DELIVERY_SCREEN_DATA,
  PROCESSING_SCREEN_DATA,
  MANAGEMENT_SCREEN_DATA,
];

export const FeaturesScreen = React.forwardRef((props, ref) => (
  <FeaturesScreenContainer id="top" data-navbar-theme="dark" ref={ref}>
    <Wrapper>
      <FeaturesBigHeading>Platform</FeaturesBigHeading>
      <FeaturesScreenDescription>
        Power up file uploading, processing and delivery for your app in one sitting.
      </FeaturesScreenDescription>

      <FeaturesScreenCardsWrapper>
        {FEATURES_CARD_DATA.map(({ header, headerIcon, sections = [] }) => (
          <FeaturesScreenCard key={header}>
            <CardIcon uuid={headerIcon} />
            <CardHeading>{header}</CardHeading>
            <CardList>
              {sections.map((feature) => (
                <CardListItem key={feature.name} to={`#${toKebabCase(header + feature.name)}`}>
                  {feature.name}
                </CardListItem>
              ))}
            </CardList>
          </FeaturesScreenCard>
        ))}
      </FeaturesScreenCardsWrapper>
    </Wrapper>
  </FeaturesScreenContainer>
));

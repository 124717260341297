import React, { useRef } from 'react';

import { SiteLayout } from '../components/Layout/SiteLayout';

import {
  FeaturesScreen,
  UploadingScreen,
  DeliveryScreen,
  ManagementScreen,
  ProcessingScreen,
  ToStartButton,
} from '../components/forFeatures';
import { SmallCTA } from '../components/SmallCTA';

export const frontmatter = {
  title: 'Platform Features — Uploadcare',
  description:
    'Uploadcare’s file uploading and delivery platform features. It’s built to power your app with one powerful solution.',
};

const Features = ({ location }) => {
  const firstScreenRef = useRef(null);

  return (
    <SiteLayout meta={frontmatter} pathName={location.pathname} isDark>
      <FeaturesScreen ref={firstScreenRef} />
      <UploadingScreen />
      <DeliveryScreen />
      <ProcessingScreen />
      <ManagementScreen />
      <SmallCTA />

      <ToStartButton firstScreenRef={firstScreenRef} />
    </SiteLayout>
  );
};

export default Features;
